import { React, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';

import { fetchFlask } from '../../utils/fetchFlask';
import { Alerta } from '../../components/alerta';

const FinalizarProtocolo = () => {
    const [cc, setCC] = useState('');
    const [dateTime, setDateTime] = useState(null);
    const [error, setError] = useState(false);
    const [statusProtocolo, setStatusProtocolo] = useState('');
    const [categoria, setCategoria] = useState('');
    const [descricao, setDescricao] = useState('');
    const [listDescricao, setListDescricao] = useState([]);
    const [message, setMessage] = useState('');
    const [statusAlerta, setStatusAlerta] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);

    const selectCategoria = (e) => {
        const catAtual = e.target.value;
        setCategoria(catAtual);

        const descricoes = {
            Alteração: ['Titularidade', 'Demanda', 'Rateio', 'Enquadramento'],
            Solicitação: ['Segunda Via', 'Demonstrativo'],
            Correção: ['Faturamento', 'Injetado'],
            'Rede Celpe': ['Falta de Energia', 'Qualidade', 'Religação', 'Falta de fase',
                           'Desligamento Definitivo', 'Elo Fusível', 'Chave Faca', 'Sobretensão', 'Não Especificado']
        };
        setListDescricao(descricoes[catAtual]);
        setDescricao('');
    };

    async function submitFinalizarProtocolos (e) {
        e.preventDefault();

        let data = '';
        if (dateTime) {
            setError(false);
            data = dateTime.toISOString().slice(0, 10);
        } else {
            setError(true);
            return;
        }

        const body = JSON.stringify({
            cc,
            dataFinal: data,
            statusProtocolo,
            descricao
        });
        const response = await fetchFlask('fetchFinalizarProtocolo', body);
        setMessage(response.message);
        setStatusAlerta(response.status);
        setOpenAlerta(true);
        if (response.status === 'success') {
            setCC('');
            setDateTime(null);
            setCategoria('');
            setDescricao('');
        }
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={statusAlerta}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                Finalizar Protocolo
            </Typography>

            <form action='' method='POST' id='formFinalizarProtocolo' onSubmit={submitFinalizarProtocolos}>
                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Conta Contrato'
                        value={cc}
                        onChange={(e) => setCC(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ marginTop: '20px' }}>
                    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker id='data' label='Data de Finalização'
                            minDate={new Date('2023-01-02')}
                            maxDate={new Date()}
                            value={dateTime}
                            onChange={(value) => setDateTime(value)}
                            slotProps={{
                                openPickerButton: {
                                    color: 'primary'
                                }
                            }}
                        />
                        {error && (
                            <Typography variant='caption' color='red'>
                                Por favor selecione uma data!
                            </Typography>
                        )}
                    </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <InputLabel id='labelStatus'>Status de Conclusão</InputLabel>
                    <Select
                        id='status'
                        labelId='labelStatus'
                        label='Status de Conclusão'
                        sx={{ width: '300px' }}
                        value={statusProtocolo}
                        onChange={(e) => setStatusProtocolo(e.target.value)}
                        required
                    >
                        <MenuItem value='CONCLUÍDO'>Concluído</MenuItem>
                        <MenuItem value='ENCERRADO'>Encerrado</MenuItem>
                    </Select>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <InputLabel id='labelCat'>Categoria</InputLabel>
                    <Select
                        id='categoria'
                        labelId='labelCat'
                        label='Categoria'
                        sx={{ width: '300px' }}
                        value={categoria}
                        onChange={selectCategoria}
                        required
                    >
                        <MenuItem value='Alteração'>Alteração</MenuItem>
                        <MenuItem value='Solicitação'>Solicitação</MenuItem>
                        <MenuItem value='Correção'>Correção</MenuItem>
                        <MenuItem value='Rede Celpe'>Rede Celpe</MenuItem>
                    </Select>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <InputLabel id='labelDescricao'>Descrição</InputLabel>
                    <Select
                        id='descriacao'
                        labelId='labelDescricao'
                        label='Descrição'
                        sx={{ width: '300px' }}
                        value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                        required
                    >
                        {listDescricao.map((item) => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <Button type='submit' variant='contained'>Finalizar Protocolo</Button>
                </FormControl>
            </form>
        </Box>
    );
};

export default FinalizarProtocolo;
