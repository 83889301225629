import { React, useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { Button } from '@mui/material';
import dayjs from 'dayjs';

import { Alerta } from '../../components/alerta';
import { fetchFlask } from '../../utils/fetchFlask';

const ChangeSchedule = () => {
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [status, setStatus] = useState('');
    const [time, setTime] = useState(null);
    const [openAlerta, setOpenAlerta] = useState(false);

    async function submitChangeSchedule (e) {
        e.preventDefault();

        if (time) {
            setError(false);
        } else {
            setError(true);
            return;
        }

        const selectedTime = dayjs(time);
        const timeUTC3 = selectedTime.add(3, 'hour').format('HH:mm');

        const body = JSON.stringify({
            time: timeUTC3
        });

        const response = await fetchFlask('changeSchedule', body);

        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    };

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3' sx={{ marginBottom: '30px' }}>
                Alterar Horário Servidor
            </Typography>

            <form action='' id='formChangeSchedule' onSubmit={submitChangeSchedule}>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <TimeField
                        id='timeSchedule'
                        label="Horário"
                        defaultValue={dayjs('2022-04-17T20:00')}
                        value={time}
                        onChange={(value) => setTime(value)}
                        format="HH:mm"
                    />
                    {error && (
                        <Typography variant='caption' color='red'>
                            Por favor selecione um horario!
                        </Typography>
                    )}
                </LocalizationProvider>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <Button type='submit' variant='contained'>Alterar Horário</Button>
                </FormControl>
            </form>
        </Box>
    );
};

export default ChangeSchedule;
