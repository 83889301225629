import { React, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import styles from '../css/geracaoCSS.module.css';
import { fetchFlask } from '../../utils/fetchFlask';
import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';

const SubstituirInversor = () => {
    const [dateTime, setDateTime] = useState(null);
    const [error, setError] = useState(false);
    const [monitoramento, setMonitoramento] = useState('');
    const [modelo, setModelo] = useState('');
    const [potNom, setPotNom] = useState('');
    const [potPico, setPotPico] = useState('');
    const [inversorAntigo, setInversorAntigo] = useState('');
    const [numSerie, setNumSerie] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    async function submitInversorChange (e) {
        e.preventDefault();
        setIsSubmit(true);

        let data = '';
        if (dateTime) {
            setError(false);
            data = dateTime.toISOString().slice(0, 10);
        } else {
            setError(true);
            setIsSubmit(false);
            return;
        }
        setError(false);

        const body = JSON.stringify({
            dataTroca: data,
            inversorAntigo,
            monitoramento,
            inversor: modelo,
            potencia_nominal: potNom,
            potencia_pico: potPico,
            num_serie: numSerie
        });

        const response = await fetchFlask('substituirInversor', body);
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
        if (response.status === 'success') {
            setDateTime(null);
            setInversorAntigo('');
            setNumSerie('');
            setPotNom('');
            setPotPico('');
            setModelo('');
            setMonitoramento('');
        }
        setIsSubmit(false);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>Substituir Inversor</Typography>
            <form action='' method='POST' id='formReplaceInversor' onSubmit={submitInversorChange}>
                <Box className={styles.row}>
                    <Box className={styles.column}>
                        <FormControl sx={{ marginTop: '20px' }} >
                            <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                                <DatePicker id='data' label='Dia da troca'
                                    minDate={new Date('2023-01-02')}
                                    maxDate={new Date()}
                                    onChange={(value) => setDateTime(value)}
                                    slotProps={{
                                        openPickerButton: {
                                            color: 'primary'
                                        }
                                    }}
                                />
                                {error && (
                                    <Typography variant='caption' color='red'>
                                        Por favor selecione uma data!
                                    </Typography>
                                )}
                            </LocalizationProvider>
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                label='Inversor Antigo - Serial'
                                id='OldInverter'
                                multiline
                                maxRows={4}
                                sx={{ width: '300px' }}
                                value={inversorAntigo}
                                onChange={(e) => setInversorAntigo(e.target.value)}
                            />
                        </FormControl>
                    </Box>

                    <Box className={styles.column}>
                        <Typography variant='h4'>Novo Inversor</Typography>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                label='Número de Série'
                                id='numSerie'
                                multiline
                                maxRows={4}
                                sx={{ width: '300px' }}
                                value={numSerie}
                                onChange={(e) => setNumSerie(e.target.value)}
                            />
                        </FormControl>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                label='Potência Pico'
                                id='kWp'
                                multiline
                                maxRows={4}
                                sx={{ width: '300px' }}
                                value={potPico}
                                onChange={(e) => setPotPico(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                label='Potência Nominal'
                                id='kWn'
                                multiline
                                maxRows={4}
                                sx={{ width: '300px' }}
                                value={potNom}
                                onChange={(e) => setPotNom(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                label='Modelo do Inversor'
                                id='modelo'
                                multiline
                                maxRows={4}
                                sx={{ width: '300px' }}
                                value={modelo}
                                onChange={(e) => setModelo(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <InputLabel id='labelMon'>Portal de Monitoramento</InputLabel>
                            <Select
                                labelId='labelMon'
                                label='Portal de Monitoramento'
                                sx={{ width: '400px' }}
                                value={monitoramento}
                                onChange={(e) => setMonitoramento(e.target.value)}
                                required
                            >
                                <MenuItem value='CANADIAN'>CANADIAN</MenuItem>
                                <MenuItem value='SUNGROW'>SUNGROW</MenuItem>
                                <MenuItem value='SICES'>SICES</MenuItem>
                                <MenuItem value='SOLARVIEW'>SOLARVIEW</MenuItem>
                                <MenuItem value='GROWATT'>GROWATT</MenuItem>
                                <MenuItem value='FRONIUS'>FRONIUS</MenuItem>
                                <MenuItem value='SUNWAYS'>SUNWAYS</MenuItem>
                                <MenuItem value='PV OPERATION'>PV OPERATION</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                        <SubmitButton isSubmit={isSubmit}>Confirmar Substituição</SubmitButton>
                        </FormControl>
                    </Box>
                </Box>
            </form>
        </Box>
    );
};

export default SubstituirInversor;
