import { React, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import CheckboxInput from '../../components/CheckboxInput';
import { fetchFlask } from '../../utils/fetchFlask';
import { Alerta } from '../../components/alerta';

const AlterarContato = () => {
    const [dados, setDados] = useState([]);
    const [cliente, setCliente] = useState('');
    const [listaClientes, setListaCLientes] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [listaUnidades, setListaUnidades] = useState([]);
    const [ddd, setDDD] = useState('');
    const [dddDois, setDDDdois] = useState('');
    const [telefone, setTelefone] = useState('');
    const [telefoneDois, setTelefoneDois] = useState('');
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [emailDois, setEmailDois] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);

    useEffect(() => {
        async function buscarContatos () {
            const response = await fetchFlask('buscarClientesContato');
            if (response.status !== 'error') {
                const dadosFetch = response.dados;
                setDados(dadosFetch);
                const dadosClientes = [...new Set(dadosFetch.map(item => item[0]))];
                setListaCLientes(dadosClientes);
            } else {
                handleAlerta(response);
            }
        };
        buscarContatos();
    }, []);

    const selectCliente = (e) => {
        setCliente(e.target.value);

        const dadosFiltrados = dados.filter(item => item[0] === e.target.value);
        const dadosUnidades = [...new Set(dadosFiltrados.map(item => item[1]))];

        const unidadesCliente = ['Selecionar Todas'];
        for (const unidade of dadosUnidades) {
            let nomeUnidade = unidade + ' | ';
            const ccsUnidade = dadosFiltrados.filter(item => item[1] === unidade).sort();
            for (const lista of ccsUnidade) {
                nomeUnidade = nomeUnidade + lista[2] + ' / ';
            }
            unidadesCliente.push(nomeUnidade.slice(0, -3));
        }
        setListaUnidades(unidadesCliente);
        setUnidades([]);
    };

    const selectUnidades = (e, values) => {
        if (values.includes('Selecionar Todas')) {
            let todasUnidades = [];
            todasUnidades = todasUnidades.concat(listaUnidades);
            todasUnidades.splice(0, 1);
            setUnidades(todasUnidades);
        } else {
            setUnidades(values);
        }
    };

    async function submitAlterarContrato (e) {
        e.preventDefault();

        if (!ddd && !telefone && !telefoneDois && !nome && !email && !emailDois && !cpfCnpj && !dddDois) {
            alert('Selecione pelo menos uma opção para alterar!!');
            return;
        }

        const unidadesFetch = [];
        for (const unidade of unidades) {
            unidadesFetch.push(unidade.split(' | ')[0]);
        }

        const body = JSON.stringify({
            unidades: unidadesFetch,
            ddd,
            telefone,
            dddDois,
            telefoneDois,
            nome,
            email,
            emailDois,
            cpfCnpj
        });

        const response = await fetchFlask('alterarContato', body);
        handleAlerta(response);
        if (response.message === 'success') {
            setDDD('');
            setTelefone('');
            setDDDdois('');
            setTelefoneDois('');
            setNome('');
            setEmail('');
            setEmailDois('');
            setCpfCnpj('');
        }
    };

    function handleAlerta (response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>Alterar Contato</Typography>

            <form action='' method='POST' id='formAlterarDados' onSubmit={submitAlterarContrato}>
                <Box className='row'>
                    <Box className='column'>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                            <InputLabel id='labelCliente'>Cliente</InputLabel>
                            <Select
                                id='selectCliente'
                                labelId='labelCliente'
                                label='Cliente'
                                value={cliente}
                                onChange={selectCliente}
                                sx={{ width: '300px' }}
                                required
                            >
                                {listaClientes.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                            <Autocomplete
                                sx={{ width: '500px' }}
                                multiple
                                disableCloseOnSelect
                                id='unidades'
                                limitTags={8}
                                value={unidades}
                                options={listaUnidades}
                                onChange={selectUnidades}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Unidades"
                                    required={unidades.length === 0}
                                    />
                                )}
                            />
                        </FormControl>
                    </Box>
                    <Box className='column'>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                            <CheckboxInput
                                label='DDD'
                                value={ddd}
                                setValue={setDDD}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                            <CheckboxInput
                                label='Telefone'
                                value={telefone}
                                setValue={setTelefone}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                            <CheckboxInput
                                label='DDD 2'
                                value={dddDois}
                                setValue={setDDDdois}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                            <CheckboxInput
                                label='Telefone 2'
                                value={telefoneDois}
                                setValue={setTelefoneDois}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                            <CheckboxInput
                                label='Nome'
                                value={nome}
                                setValue={setNome}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                            <CheckboxInput
                                label='Email'
                                value={email}
                                setValue={setEmail}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                            <CheckboxInput
                                label='Email 2'
                                value={emailDois}
                                setValue={setEmailDois}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                            <CheckboxInput
                                label='CPF ou CNPJ'
                                value={cpfCnpj}
                                setValue={setCpfCnpj}
                                mask={'cpfCnpj'}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <Button type='submit' variant="contained">Alterar Contato</Button>
                        </FormControl>
                    </Box>
                </Box>
            </form>
        </Box>
    );
};

export default AlterarContato;
