import { React, useState } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import { setToken } from '../utils/loginTokens';
import { fetchFlask } from '../utils/fetchFlask';
import { Alerta } from '../components/alerta';
import styles from '../pages/css/login.module.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);

    async function submitLogin (e) {
        e.preventDefault();

        const body = JSON.stringify({
            username,
            password
        });

        const response = await fetchFlask('login', body);
        if ('accessToken' in response) {
            setToken(response.accessToken);
        } else {
            setMessage(response.message);
            setStatus(response.status);
            setOpenAlerta(true);
        }
    };

    return (
        <Grid >
            <Grid id={styles.image} ></Grid>
            <Grid id={styles.boxLogin}>
                <Alerta
                    texto={message}
                    status={status}
                    open={openAlerta}
                    handleClose={() => setOpenAlerta(false)}
                />
                <Card id={styles.loginCard} >
                    <Box id={styles.boxLogo}>
                        <Box id={styles.logoSetta} ></Box>
                    </Box>
                    <form action='POST' onSubmit={submitLogin}>
                        <Box className={styles.boxLabel}>
                            <Typography id={styles.emailLabel} >E-mail</Typography>
                            <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                                <TextField
                                    placeholder='Digite o seu E-mail'
                                    variant='standard'
                                    sx={{
                                        width: '325px',
                                        '& .MuiInput-root': {
                                            color: '#13154F',
                                            fontFamily: 'Gotham Italic',
                                            // Bottom border
                                            '&:before': {
                                                borderColor: '#13154F',
                                                borderWidth: '2px'
                                            },
                                            '&:after': {
                                                borderColor: '#13154F',
                                                borderWidth: '3px'
                                            },
                                            ':hover:not(.Mui-focused)': {
                                                '&:before': {
                                                    borderColor: '#13154F',
                                                    borderWidth: '3px'
                                                }
                                            }
                                          }
                                    }}
                                    required
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </FormControl>
                        </Box>

                        <Box className={styles.boxLabel}>
                            <Typography id={styles.senhaLabel} >Senha</Typography>
                            <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                                <TextField
                                    variant='standard'
                                    sx={{
                                        width: '325px',
                                        '& .MuiInput-root': {
                                            color: '#13154F',
                                            fontFamily: 'Gotham Italic',
                                            // Bottom border
                                            '&:before': {
                                                borderColor: '#13154F',
                                                borderWidth: '2px'
                                            },
                                            '&:after': {
                                                borderColor: '#13154F',
                                                borderWidth: '3px'
                                            },
                                            ':hover:not(.Mui-focused)': {
                                                '&:before': {
                                                    borderColor: '#13154F',
                                                    borderWidth: '3px'
                                                }
                                            }
                                          }
                                    }}
                                    required
                                    placeholder='Digite sua senha'
                                    type='password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </FormControl>
                        </Box>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <Button id={styles.submitLogin} type='submit' variant='contained'>Login</Button>
                        </FormControl>
                    </form>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Login;
