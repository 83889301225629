import { React } from 'react';

import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '../components/protectedRoutes';
import AddClient from '../pages/comercial/addClient';
import CadastrarLeadCRM from '../pages/comercial/cadastrarLead';
import CreateDocuments from '../pages/comercial/criarDocumentos';
import GerarProposta from '../pages/comercial/gerarProposta';
import AddAdditiveCC from '../pages/gestaoEnergia/addAdditiveCC';
import GenerateAdditive from '../pages/comercial/generateAdditive';

const RouteComercialInterno = () => {
    return (
        <Routes>
            <Route element={<ProtectedRoute role='Interno'/>}>
                <Route path='addAdditive' element={<AddAdditiveCC/>}/>
                <Route path='generateAdditive' element={<GenerateAdditive/>}/>
                <Route path='addClient' element={<AddClient/>}/>
                <Route path='createDocuments' element={<CreateDocuments/>}/>
                <Route path='gerarProposta' element={<GerarProposta/>}/>
                <Route path='cadastrarLeadCRM' element={<CadastrarLeadCRM/>}/>
            </Route>
        </Routes>
    );
};

export default RouteComercialInterno;
