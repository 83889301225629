import { React, useEffect, useState } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { pt } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { Alerta } from '../../components/alerta';
import { fetchFlask } from '../../utils/fetchFlask';
import SubmitButton from '../../components/submitButton';

const NotificarCliente = () => {
    const [cliente, setCliente] = useState('');
    const [listaClientes, setListaCLientes] = useState([]);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [ccAntiga, setCCAntiga] = useState('');
    const [ccNova, setCCNova] = useState('');
    const [error, setError] = useState(false);
    const [valorCobrar, setValorCobrar] = useState('');
    const [cc, setCC] = useState('');
    const [dateTime, setDateTime] = useState(null);
    const [whatsAppChecked, setWhatsAppChecked] = useState(false);
    const [emailChecked, setEmailChecked] = useState(false);
    const [openAlerta, setOpenAlerta] = useState(false);
    const [tipoNotificacao, setTipoNotificacao] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        async function buscarContatos () {
            const response = await fetchFlask('buscarClientesContato');
            if (response.status !== 'error') {
                const dadosFetch = response.dados;
                const dadosClientes = [...new Set(dadosFetch.map(item => item[0]))];
                setListaCLientes(dadosClientes);
            } else {
                handleAlerta(response);
            }
        };
        buscarContatos();
    }, []);

    async function submitNotificarCliente (e) {
        e.preventDefault();
        setIsSubmit(true);

        if (!dateTime && tipoNotificacao === 'Faturamento') {
            setError(true);
            setIsSubmit(false);
            return;
        }
        setError(false);

        let body = {};
        if (tipoNotificacao === 'TrocaTitularidade') {
            body = JSON.stringify({
                tipoNotificacao,
                ccAntiga,
                ccNova,
                notificarWhatsApp: whatsAppChecked,
                notificarEmail: emailChecked
            });
        } else if (tipoNotificacao === 'NovoCliente') {
            body = JSON.stringify({
                tipoNotificacao,
                cliente,
                notificarWhatsApp: whatsAppChecked,
                notificarEmail: emailChecked
            });
        } else if (tipoNotificacao === 'Faturamento') {
            const ano = dateTime.getFullYear();
            const mes = dateTime.getMonth() + 1;
            const periodo = ano + '-' + mes;

            body = JSON.stringify({
                tipoNotificacao,
                cc,
                periodo,
                valorCobrar
            });
        };;

        const response = await fetchFlask('notificarCliente', body);
        handleAlerta(response);
        setIsSubmit(false);
    };

    function handleAlerta (response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>Notificar Cliente</Typography>

            <form action='' method='POST' id='formNotificarCliente' onSubmit={submitNotificarCliente}>
                <Box className='row'>
                    <Box className='column'>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <InputLabel id='labelNotificacao'>Notificação</InputLabel>
                            <Select
                                id='selectNotificacao'
                                labelId='labelNotificacao'
                                label='tipoNotificacao'
                                value={tipoNotificacao}
                                onChange={(e) => setTipoNotificacao(e.target.value)}
                                sx={{ width: '220px' }}
                            >
                                <MenuItem value={'NovoCliente'}>Novo Cliente</MenuItem>
                                <MenuItem value={'TrocaTitularidade'}>Troca de Titularidade</MenuItem>
                                <MenuItem value={'Faturamento'}>Faturamento</MenuItem>
                            </Select>
                        </FormControl>

                        {tipoNotificacao === 'NovoCliente' && (
                            <>
                                <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                                    <InputLabel id='labelCliente'>Cliente</InputLabel>
                                    <Select
                                        id='selectCliente'
                                        labelId='labelCliente'
                                        label='Cliente'
                                        value={cliente}
                                        onChange={(e) => setCliente(e.target.value)}
                                        sx={{ width: '300px' }}
                                        required
                                    >
                                        {listaClientes.map((item) => (
                                            <MenuItem key={item} value={item}>{item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        {tipoNotificacao === 'TrocaTitularidade' && (
                            <>
                                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                                    <TextField
                                        required
                                        label='CC Antiga'
                                        id='ccAntiga'
                                        sx={{ width: '250px' }}
                                        value={ccAntiga}
                                        onChange={(e) => setCCAntiga(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                                    <TextField
                                        required
                                        label='CC Nova'
                                        id='ccNova'
                                        sx={{ width: '250px' }}
                                        value={ccNova}
                                        onChange={(e) => setCCNova(e.target.value)}
                                    />
                                </FormControl>
                            </>
                        )}

                        {tipoNotificacao === 'Faturamento' && (
                            <>
                                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                                    <TextField
                                        required
                                        label='CC'
                                        id='cc'
                                        sx={{ width: '250px' }}
                                        value={cc}
                                        onChange={(e) => setCC(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl sx={{ marginTop: '20px' }}>
                                    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                                        <DatePicker id='data' label='Período'
                                            views={['month', 'year']}
                                            openTo='month'
                                            minDate={new Date('2023-01-02')}
                                            maxDate={new Date()}
                                            value={dateTime}
                                            onChange={(value) => setDateTime(value)}
                                            slotProps={{
                                                openPickerButton: {
                                                    color: 'primary'
                                                }
                                            }}
                                        />
                                        {error && (
                                            <Typography variant='caption' color='red'>
                                                Por favor selecione uma data!
                                            </Typography>
                                        )}
                                    </LocalizationProvider>
                                </FormControl>

                                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                                    <TextField
                                        required
                                        label='Valor da Conta'
                                        type='number'
                                        inputProps={{ step: 0.01, min: 0 }}
                                        value={valorCobrar}
                                        onChange={(e) => setValorCobrar(e.target.value)}
                                    />
                                </FormControl>
                            </>
                        )}

                        {tipoNotificacao && tipoNotificacao !== 'Faturamento' && (
                            <>
                                <FormControlLabel
                                    sx={{ display: 'block', marginTop: '20px' }}
                                    control={
                                        <Checkbox
                                            label='whatsApp'
                                            checked={whatsAppChecked}
                                            tabIndex={-1}
                                            disableRipple
                                            onChange={() => setWhatsAppChecked(!whatsAppChecked)}
                                        />
                                    }
                                    label="WhatsApp"
                                />

                                <FormControlLabel
                                    sx={{ display: 'block' }}
                                    control={
                                        <Checkbox
                                            label='email'
                                            checked={emailChecked}
                                            tabIndex={-1}
                                            disableRipple
                                            onChange={() => setEmailChecked(!emailChecked)}
                                        />
                                    }
                                    label="Email"
                                />
                            </>
                        )}

                        {tipoNotificacao && (
                            <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                                <SubmitButton isSubmit={isSubmit} >Notificar Cliente</SubmitButton>
                            </FormControl>
                        )}

                    </Box>
                </Box>
            </form>
        </Box>
    );
};

export default NotificarCliente;
