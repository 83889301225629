import { React, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import styles from '../pages/css/geracaoCSS.module.css';
import { fetchFlask } from '../utils/fetchFlask';
import { Alerta, PopupDialog } from '../components/alerta';

const InserirGeracaoIndividual = () => {
    const [dateTime, setDateTime] = useState(null);
    const [error, setError] = useState(false);
    const [dados, setDados] = useState([]);
    const [site, setSite] = useState('');
    const [listaSites, setListaSites] = useState([]);
    const [usina, setUsina] = useState('');
    const [listaUsinas, setListaUsinas] = useState([]);
    const [inversor, setInversor] = useState('');
    const [listaInv, setListaInv] = useState([]);
    const [geracao, setGeracao] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [dataStr, setDataStr] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [textoDialog, setTextoDialog] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);

    useEffect(() => {
        async function pegarDados () {
            const response = await fetchFlask('pegarDados');
            if (response.status !== 'error') {
                const dadosFetch = response.dados;
                setDados(dadosFetch);
                const dadosSite = [...new Set(dadosFetch.map(item => item[0]))];
                setListaSites(dadosSite);
            } else {
                handleAlerta(response);
            }
        };
        pegarDados();
    }, []);

    const selectSite = (e) => {
        setSite(e.target.value);
        const dadosFiltrados = dados.filter(item => item[0] === e.target.value);
        const dadosusinas = [...new Set(dadosFiltrados.map(item => item[1]))];
        dadosusinas.sort();
        setListaUsinas(dadosusinas);
        setUsina('');
        setInversor('');
    };

    const selectUsinas = (e) => {
        setUsina(e.target.value);
        const dadosFiltrados = dados.filter(item => item[1] === e.target.value);
        const dadosInv = dadosFiltrados.map(item => item[2]);
        dadosInv.sort();
        setListaInv(dadosInv);
        setInversor('');
    };

    async function submitGeracao (e) {
        e.preventDefault();

        let data = '';
        if (dateTime) {
            setError(false);
            data = dateTime.toISOString().slice(0, 10);
        } else {
            setError(true);
            return;
        }

        const body = JSON.stringify({
            data,
            inversores: inversor
        });

        const response = await fetchFlask('geracaoAtual', body);
        if (response.status === 'error') {
            handleAlerta(response);
        } else if (response.ger_atual === null) {
            setMessage('Não existe geração para esse dia, favor verificar as informações inseridas');
            setStatus('warning');
            setOpenAlerta(true);
        } else {
            setDataStr(data);
            handleDialog(response.ger_atual);
        }
    };

    async function atualizarGeracao () {
        const body = JSON.stringify({
            data: dataStr,
            inversores: inversor,
            geracao
        });
        const response = await fetchFlask('atualizarGeracao', body);
        handleAlerta(response);
        if (response.status === 'success') {
            setDateTime(null);
            setSite('');
            setUsina('');
            setInversor('');
            setGeracao('');
        }
    }

    function handleDialog (gerAtual) {
        setTextoDialog('Geração atual é ' + gerAtual + ', deseja modificar para ' + geracao + '?');
        setOpenDialog(true);
    }

    function handleAlerta (response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    return (
        <>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <PopupDialog
                titulo={'Atualizar Geração?'}
                texto={textoDialog}
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                funcao={atualizarGeracao}
            />
            <Typography variant='h3'>Inserir Geração</Typography>

            <form action='' method='POST' id='formGeracao' onSubmit={submitGeracao}>
                <Box className={styles.row}>
                    <Box className={styles.column}>
                        <FormControl sx={{ marginTop: '20px' }} >
                            <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                                <DatePicker id='data' label='Dia da Geração'
                                    minDate={new Date('2023-01-02')}
                                    maxDate={new Date()}
                                    value={dateTime}
                                    onChange={(value) => setDateTime(value)}
                                    slotProps={{
                                        openPickerButton: {
                                            color: 'primary'
                                        }
                                    }}
                                />
                                {error && (
                                    <Typography variant='caption' color='red'>
                                        Por favor selecione uma data!
                                    </Typography>
                                )}
                            </LocalizationProvider>
                        </FormControl >

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <InputLabel id='labelSite'>Site</InputLabel>
                            <Select
                                id='site'
                                labelId='labelSite'
                                label='Site'
                                value={site}
                                onChange={selectSite}
                                sx={{ width: '300px' }}
                                required
                            >
                                {listaSites.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <InputLabel id='labelUsinas'>Usinas</InputLabel>
                            <Select
                                id='usinas'
                                labelId='labelUsinas'
                                label='Usinas'
                                value={usina}
                                onChange={selectUsinas}
                                sx={{ width: '300px' }}
                                required
                            >
                                {listaUsinas.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <InputLabel id='labelUsinas'>Inversores</InputLabel>
                            <Select
                                id='usinas'
                                labelId='labelUsinas'
                                label='Inversores'
                                value={inversor}
                                onChange={(e) => setInversor(e.target.value)}
                                sx={{ width: '300px' }}
                                required
                            >
                                {listaInv.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className={styles.column}>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                id='geracao'
                                label='Geração'
                                type='number'
                                inputProps={{ step: 0.01, min: -1 }}
                                required
                                onChange={(e) => setGeracao(e.target.value)}
                                value={geracao}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <Button type='submit' variant='contained'>Inserir Geração</Button>
                        </FormControl>
                    </Box>
                </Box>

            </form>
        </>
    );
};

export default InserirGeracaoIndividual;
