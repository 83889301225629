import { React, useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';
import { fetchFlask } from '../../utils/fetchFlask';

const ListarDebitos = () => {
    const [arquivo, setArquivo] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);

    async function submitListarDebitos (e) {
        e.preventDefault();
        setIsSubmit(true);

        const formData = new FormData();
        formData.append('arquivoCSV', arquivo);

        const response = await fetchFlask('listarDebitos', formData, true);
        setStatus(response.status);
        setOpenAlerta(true);
        if (response.status !== 'error') {
            const pdfBlob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
            const pdfObjectURL = URL.createObjectURL(pdfBlob);
            window.open(pdfObjectURL, '_blank');
            setArquivo(null);
            setMessage('Arquivo gerado com sucesso!');
        } else {
            setMessage(response.message);
        }
        setIsSubmit(false);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                LISTAR DÉBITOS DE CCs - TROCAS NEGADAS
            </Typography>

            <form action='' method='POST' id='listarDebitos' onSubmit={submitListarDebitos}>
                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <Typography>ARQUIVO CSV: CC | DOCUMENTO | DISTRIBUIDORA </Typography>
                    <TextField
                        type='file'
                        size='small'
                        inputProps={{ accept: 'text/csv' }}
                        onChange={(e) => setArquivo(e.target.files[0])}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <SubmitButton isSubmit={isSubmit}>GERAR CSV</SubmitButton>
                </FormControl>
            </form>
        </Box>
    );
};

export default ListarDebitos;
