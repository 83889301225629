import { createTheme } from '@mui/material';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#13154F'
        },
        secondary: {
            main: '#FFA300'
        }
    },
    typography: {
        fontFamily: 'Gotham',
        body1: {
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.5
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: 1.57
        },
        subtitle1: {
            fontSize: '1rem',
            fontWeight: 500,
            lineHeight: 1.75
        },
        subtitle2: {
            fontSize: '0.875rem',
            fontWeight: 500,
            lineHeight: 1.57
        },
        overline: {
            fontSize: '0.75rem',
            fontWeight: 600,
            letterSpacing: '0.5px',
            lineHeight: 2.5,
            textTransform: 'uppercase'
        },
        caption: {
            fontSize: '0.75rem',
            fontWeight: 400,
            lineHeight: 1.66
        },
        h1: {
            fontWeight: 700,
            fontSize: '3.5rem',
            lineHeight: 1.375
        },
        h2: {
            fontWeight: 700,
            fontSize: '3rem',
            lineHeight: 1.375
        },
        h3: {
            fontWeight: 700,
            fontSize: '2.25rem',
            lineHeight: 1.375
        },
        h4: {
            fontWeight: 700,
            fontSize: '2rem',
            lineHeight: 1.375
        },
        h5: {
            fontWeight: 600,
            fontSize: '1.5rem',
            lineHeight: 1.375
        },
        h6: {
            fontWeight: 600,
            fontSize: '1.125rem',
            lineHeight: 1.375
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    color: '#13154F',
                    backgroundColor: '#FFA300',
                    fontFamily: 'Gotham',
                    fontWeight: '700',
                    fontSize: '17px',
                    '&:hover': {
                        backgroundColor: '#FFA300'
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        color: '#13154F',
                        fontFamily: 'Gotham',
                        fontWeight: '500',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#13154F',
                          borderWidth: '2px',
                          borderRadius: '30px'
                        },
                        '&.Mui-focused': {
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#13154F',
                              borderWidth: '2px'
                            }
                        }
                    },
                    '& .MuiInputLabel-outlined': {
                        color: '#13154F',
                        fontFamily: 'Gotham',
                        fontWeight: '400',
                        '&.Mui-focused': {
                            color: '#13154F',
                            fontWeight: 'bold'
                        }
                    }
                }
            }
        }
    }
});
