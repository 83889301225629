import { React, useState } from 'react';

import { FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';

import ComercialCRM from '../../components/comercialCRM';
import PropostaDigital from '../../components/propostaDigital';

const GerarProposta = (props) => {
    const [tipo, setTipo] = useState('PF');

    return (
        <Box className='mainBox' padding={'20px'}>

            <FormControlLabel label="PESSOA FÍSICA | PESSOA JURÍDICA"
                control={
                    <Switch
                        onChange={(e) => {
                            setTipo(e.target.checked ? 'PJ' : 'PF');
                        }}
                    />
                }
            />
            {tipo === 'PF' ? <PropostaDigital consultor={props.consultor} /> : <ComercialCRM /> }
        </Box>
    );
};

export default GerarProposta;
