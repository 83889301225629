import { React } from 'react';

import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '../components/protectedRoutes';
import PropostaComercial from '../pages/comercial/propostaComercial';

const RouteExterno = () => {
    return (
        <Routes>
            <Route element={<ProtectedRoute role='Externo'/>}>
                <Route path='comercialCRM' element={<PropostaComercial/>}/>
            </Route>
        </Routes>
    );
};

export default RouteExterno;
