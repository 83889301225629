import { React, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';

import { Alerta } from '../../components/alerta';
import { fetchFlask } from '../../utils/fetchFlask';

const AlertasPVOperation = () => {
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [dateTimeInicio, setDateTimeInicio] = useState(null);
    const [errorInicio, setErrorInicio] = useState(false);
    const [dateTimeFinal, setDateTimeFinal] = useState(null);
    const [errorFinal, setErrorFinal] = useState(false);

    async function submitAlertasPVOperation (e) {
        e.preventDefault();

        let inicio = '';
        if (dateTimeInicio) {
            setErrorInicio(false);
            inicio = dateTimeInicio.toISOString().slice(0, 10);
        } else {
            setErrorInicio(true);
            return;
        }

        let fim = '';
        if (dateTimeFinal) {
            setErrorFinal(false);
            fim = dateTimeFinal.toISOString().slice(0, 10);
        } else {
            setErrorFinal(true);
            return;
        }

        const body = JSON.stringify({
            inicio,
            fim
        });

        const response = await fetchFlask('falhasPVOperation', body, true);

        if (response.status !== 'error') {
            const csvBlob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
            const pdfObjectURL = URL.createObjectURL(csvBlob);
            window.open(pdfObjectURL, '_blank');
        } else {
            setMessage(response.message);
            setStatus(response.status);
            setOpenAlerta(true);
        }
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>GERAR CSV - ALERTAS PV OPERATION</Typography>

            <form method='POST' onSubmit={submitAlertasPVOperation}>
                <Box className='row'>
                    <FormControl sx={{ marginTop: '20px' }}>
                        <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                            <DatePicker id='data' label='Data de Início'
                                minDate={new Date('2023-01-02')}
                                maxDate={new Date()}
                                value={dateTimeInicio}
                                onChange={(value) => setDateTimeInicio(value)}
                                slotProps={{
                                    openPickerButton: {
                                        color: 'primary'
                                    }
                                }}
                            />
                            {errorInicio && (
                                <Typography variant='caption' color='red'>
                                    Por favor selecione uma data!
                                </Typography>
                            )}
                        </LocalizationProvider>
                    </FormControl>

                    <FormControl sx={{ marginTop: '20px', display: 'block' }}>
                        <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                            <DatePicker id='data' label='Data Final'
                                minDate={new Date('2023-01-02')}
                                maxDate={new Date()}
                                value={dateTimeFinal}
                                onChange={(value) => setDateTimeFinal(value)}
                                slotProps={{
                                    openPickerButton: {
                                        color: 'primary'
                                    }
                                }}
                            />
                            {errorFinal && (
                                <Typography variant='caption' color='red' sx={{ display: 'block' }}>
                                    Por favor selecione uma data!
                                </Typography>
                            )}
                        </LocalizationProvider>
                    </FormControl>

                    <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                        <Button type='submit' variant="contained">GERAR CSV</Button>
                    </FormControl>
                </Box>
            </form>
        </Box>
    );
};

export default AlertasPVOperation;
