import React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

function circularProgress () {
    const indicatorSize = 60;
    return (
        <Box >
            <CircularProgress
                size={indicatorSize}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: `${-indicatorSize / 2}px`,
                    marginLeft: `${-indicatorSize / 2}px`
                }}
            />
        </Box>
    );
}

export default circularProgress;
