import { React, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import styles from '../pages/css/geracaoCSS.module.css';
import { fetchFlask } from '../utils/fetchFlask';
import { Alerta } from '../components/alerta';

const InserirGeracaoBulk = () => {
    const [arquivo, setArquivo] = useState(null);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);

    async function atualizarGeracaoBulk (e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append('arquivoCSV', arquivo);

        const response = await fetchFlask('atualizarGeracaoBulk', formData);

        handleAlerta(response);
        if (response.status === 'success') {
            setArquivo(null);
        }
    }

    function handleAlerta (response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    return (
        <>

            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />

            <Typography variant='h3'>Inserir Geração CSV</Typography>

            <form action='' method='POST' id='formGeracaoBulk' onSubmit={atualizarGeracaoBulk}>
                <Box className={styles.row}>
                    <Box className={styles.column}>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <Typography>ARQUIVO CSV: dd/mm/YYYY | Serial | Geração </Typography>
                            <TextField
                                type='file'
                                size='small'
                                inputProps={{ accept: '*/*' }}
                                onChange={(e) => setArquivo(e.target.files[0])}
                            />
                        </FormControl>
                    </Box>

                    <Box className={styles.column}>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <Button type='submit' variant='contained'>Inserir Geração</Button>
                        </FormControl>
                    </Box>
                </Box>

            </form>
        </>
    );
};

export default InserirGeracaoBulk;
