import { React, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';

import { Alerta } from '../../components/alerta';
import { fetchFlask } from '../../utils/fetchFlask';

const RelatorioSPEs = () => {
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [dateTimePeriodo, setDateTimePeriodo] = useState(null);
    const [errorPeriodo, setErrorPeriodo] = useState(false);

    async function submitRelatorioSPEs (e) {
        e.preventDefault();

        let periodo = '';
        if (dateTimePeriodo) {
            setErrorPeriodo(false);
            const ano = dateTimePeriodo.getFullYear();
            const mes = dateTimePeriodo.getMonth() + 1;
            periodo = ano + '-' + mes;
        } else {
            setErrorPeriodo(true);
            return;
        }

        const body = JSON.stringify({
            periodo
        });

        const response = await fetchFlask('criarRelatorioSPEs', body, true);
        if (response.status !== 'error') {
            const pdfBlob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
            const pdfObjectURL = URL.createObjectURL(pdfBlob);
            window.open(pdfObjectURL, '_blank');
        } else {
            setMessage(response.message);
            setStatus(response.status);
            setOpenAlerta(true);
        }
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>Criar Relatório CSV</Typography>

            <form method='POST' onSubmit={submitRelatorioSPEs}>
                <Box className='row'>
                    <FormControl sx={{ marginTop: '20px' }}>
                        <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                            <DatePicker id='data' label='Período'
                                views={['month', 'year']}
                                openTo='month'
                                minDate={new Date('2023-01-02')}
                                maxDate={new Date()}
                                value={dateTimePeriodo}
                                onChange={(value) => setDateTimePeriodo(value)}
                                slotProps={{
                                    openPickerButton: {
                                        color: 'primary'
                                    }
                                }}
                            />
                            {errorPeriodo && (
                                <Typography variant='caption' color='red'>
                                    Por favor selecione uma data!
                                </Typography>
                            )}
                        </LocalizationProvider>
                    </FormControl>

                    <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                        <Button type='submit' variant="contained">GERAR CSV</Button>
                    </FormControl>
                </Box>
            </form>
        </Box>
    );
};

export default RelatorioSPEs;
