import { React, useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';
import { fetchFlask } from '../../utils/fetchFlask';

const ListarCCsConsorcio = () => {
    const [distribuidora, setDistribuidora] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);

    async function submitListarCCs (e) {
        e.preventDefault();
        setIsSubmit(true);

        const body = JSON.stringify({
            distribuidora
        });
        const response = await fetchFlask('listarCCsConsorcio', body);
        setStatus(response.status);
        setOpenAlerta(true);
        if (response.status !== 'error') {
            const pdfBlob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
            const pdfObjectURL = URL.createObjectURL(pdfBlob);
            window.open(pdfObjectURL, '_blank');
            setDistribuidora('');
            setMessage('Arquivo gerado com sucesso!');
        } else {
            setMessage(response.message);
        }
        setIsSubmit(false);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                Listar CCs Consórcio
            </Typography>

            <form action='' method='POST' id='listCCs' onSubmit={submitListarCCs}>
                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <InputLabel id='labelDistribuidora'>Distribuidora</InputLabel>
                    <Select
                        id='selectDistribuidora'
                        labelId='labelDistribuidora'
                        label='Distribuidora'
                        value={distribuidora}
                        onChange={(e) => setDistribuidora(e.target.value)}
                        sx={{ width: '220px' }}
                    >
                        <MenuItem value={'COSERN'}>COSERN</MenuItem>
                        <MenuItem value={'NEOENERGIA PE'}>NEOENERGIA PE</MenuItem>
                    </Select>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <SubmitButton isSubmit={isSubmit}>GERAR CSV</SubmitButton>
                </FormControl>
            </form>
        </Box>
    );
};

export default ListarCCsConsorcio;
