import { React, useState, useEffect } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';
import Button from '@mui/material/Button';

import { fetchFlask } from '../../utils/fetchFlask';
import { Alerta } from '../../components/alerta';

const AtualizarExtintores = () => {
    const [dados, setDados] = useState([]);
    const [site, setSite] = useState('');
    const [listaSites, setListaSites] = useState([]);
    const [usinas, setUsinas] = useState('');
    const [listaUsinas, setListaUsinas] = useState([]);
    const [fornecedor, setFornecedor] = useState('');
    const [tipo, setTipo] = useState('');
    const [dateTime, setDateTime] = useState(null);
    const [error, setError] = useState(false);
    const [obs, setObs] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);

    useEffect(() => {
        async function buscarUsinas () {
            const response = await fetchFlask('buscarUsinas');
            if (response.status !== 'error') {
                const dadosFetch = response.dados;
                setDados(dadosFetch);
                const dadosSite = [...new Set(dadosFetch.map(item => item[0]))];
                setListaSites(dadosSite);
            } else {
                handleAlerta(response);
            }
        };
        buscarUsinas();
    }, []);

    function siteSelect (e) {
        setSite(e.target.value);
        setUsinas([]);

        const dadosFiltrados = dados.filter(item => item[0] === e.target.value);
        const dadosUsinas = [...new Set(dadosFiltrados.map(item => item[1]))];
        dadosUsinas.sort();
        dadosUsinas.unshift('Selecionar Todas');
        setListaUsinas(dadosUsinas);
        setUsinas([]);
    };

    function usinasChange (e, values) {
        if (values.includes('Selecionar Todas') && !usinas.includes('Selecionar Todas')) {
            let todasUsinas = [];
            todasUsinas = todasUsinas.concat(listaUsinas);
            todasUsinas.splice(0, 1);
            setUsinas(todasUsinas);
        } else {
            setUsinas(values);
        }
    };

    async function submitAtualizarExtintores (e) {
        e.preventDefault();

        if (!dateTime) {
            setError(true);
            return;
        }
        setError(false);
        const validade = dateTime.toISOString().slice(0, 10);

        const body = JSON.stringify({
            usinas,
            fornecedor,
            tipo,
            validade,
            obs
        });
        const response = await fetchFlask('atualizarExtintores', body);
        handleAlerta(response);
        if (response.status === 'success') {
            setSite('');
            setUsinas([]);
            setFornecedor('');
            setTipo('');
            setDateTime(null);
            setObs('');
        }
    };

    function handleAlerta (response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>Atualizar Extintores</Typography>

            <form method='POST' id='formAtualizarExtintores' onSubmit={submitAtualizarExtintores}>
                <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                    <InputLabel id='labelSite'>Site</InputLabel>
                    <Select
                        id='selectSite'
                        labelId='labelSite'
                        label='Site'
                        value={site}
                        onChange={siteSelect}
                        sx={{ width: '300px' }}
                        required
                    >
                        {listaSites.map((item) => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                    <Autocomplete
                        sx={{ width: '500px' }}
                        multiple
                        disableCloseOnSelect
                        id='usinas'
                        limitTags={10}
                        value={usinas}
                        options={listaUsinas}
                        onChange={usinasChange}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            label="Usinas"
                            required={usinas.length === 0}
                            />
                        )}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Fornecedor'
                        value={fornecedor}
                        onChange={(e) => setFornecedor(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Tipo'
                        value={tipo}
                        onChange={(e) => setTipo(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ marginTop: '20px' }}>
                    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker id='data' label='Validade'
                            views={['month', 'year']}
                            openTo='month'
                            minDate={new Date('2023-01-02')}
                            value={dateTime}
                            onChange={(value) => setDateTime(value)}
                            slotProps={{
                                openPickerButton: {
                                    color: 'primary'
                                }
                            }}
                        />
                        {error && (
                            <Typography variant='caption' color='red'>
                                Por favor selecione uma data!
                            </Typography>
                        )}
                    </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        label='Observação'
                        id='obs'
                        multiline
                        maxRows={4}
                        sx={{ width: '300px' }}
                        value={obs}
                        onChange={(e) => setObs(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <Button type='submit' variant="contained">Atualizar Extintores</Button>
                </FormControl>
            </form>
        </Box>
    );
};

export default AtualizarExtintores;
