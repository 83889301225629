import { React, useContext } from 'react';

import { Outlet, Navigate } from 'react-router-dom';

import DadosUsuario from '../utils/context';

const ProtectedRoute = (props) => {
    const dadosUsuario = useContext(DadosUsuario);
    const autorizacoes = dadosUsuario.autorizacoes;

    const isAuth = autorizacoes.includes(props.role) || autorizacoes.includes('Adm');

    return isAuth ? <Outlet {...props} /> : <Navigate to='/403-Unauthorized' />;
};

export default ProtectedRoute;
